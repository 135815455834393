$base-font-family: optique-display, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$small-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;
$base-font-size: 10px;

$color-black: #000;
$color-white: #FFF;
$color-dark: #22221F;
$color-blue: #5C5CFF;
$color-background-lightblue: #E8E9EB;
$color-primary: #1B1725;
$color-secondary: #534B62;
$color-tertiary: #A499B3;
$color-accent-light: #927ABF;
$color-accent-dark: #59468A;
$color-background-red: #CE0000;