@use '../global/variables';

#root .sticky-view > .sticky-view-sticky {
    background-size: cover;
    background-position: center;
}

#root .sticky-view .full-sticky-content .full-sticky-foreground {
    width: 2000px;
    margin-left: auto;
    margin-right: auto;
    z-index: 100;
}

#root .sticky-view .full-sticky-content .full-sticky-foreground .active {
    background: variables.$color-accent-light;
}

#root .sticky-view .full-sticky-content .full-directions {
    margin-bottom: 2rem;
}

#root .sticky-view .full-sticky-content {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;    
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding-left: 5rem;
    color: variables.$color-white;
}

#root .sticky-view .full-sticky-content * {
    text-align: left;
}

#root .sticky-view .full-sticky-content .full-sticky-foreground .content-section {
    position: relative;
}

#root .sticky-view .full-sticky-content .content-text1 {
    transition: all 0.8s;
}

#root .sticky-view .full-sticky-content .content-text2 {
    transition: all 0.8s;
}

#root .sticky-view .full-sticky-content .content-text3 {
    transition: all 0.8s;
}

#root .sticky-view .full-sticky-content .content-text4 {
    transition: all 0.8s;
}

#root .sticky-view .full-sticky-content .content-text5 {
    transition: all 0.8s;
}

#root .sticky-view > .sticky-view-sticky .full-sticky-background {
    position: absolute;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

#root .sticky-view .full-sticky-content .full-sticky-background-element {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.8s;
    z-index: -1;
}

@media screen and (max-width:2000px) {

    #root .sticky-view .full-sticky-content .full-sticky-foreground {
        width: 100%;
    }

}

@media screen and (max-width:767px) {

    #root .sticky-view .full-sticky-content {
        padding-left: 2rem;
    }

}

/* Page 01 */

#root .sticky-view > .sticky-1 .full-directions {
    position: absolute;
    top: 7%;
    left: 49%;
    transform: translateY(-50%);
}

#root .sticky-view > .sticky-1 .arrow {
position: relative;
width: 42px;
height: 16px;
opacity: 0;

    &::before,
    &::after {
        content: "";
        width:21px;
        height: 2px;
        background-color: variables.$color-white;
        border-radius: 2px;
        display: inline-block;
    }

    &::before {
        transform: rotate(45deg) translateX(25%)
    }

    &::after {
        transform: rotate(-45deg) translateX(-25%)
    }

    &:nth-child(1) {
        top: -50px;
        opacity: 1;
        animation: arrow2 1s ease 0s infinite;
    }

    &:nth-child(2) {
        top: -25px;
        animation: arrow1 1s ease 0.25s infinite;
    }
    }

    @keyframes arrow1 {
    from {
        opacity: 0;
        top: -25px;
    }

    to {
        opacity: 0;
        top: 0px;
    }

    50% {
        opacity: 1;
    }
    }

    @keyframes arrow2 {
    from {
        opacity: 0;
        top: -35px;
    }

    to {
        opacity: 0;
        top: -10px;
    }

    50% {
        opacity: 1;
    }
}

#root .sticky-view > .sticky-1 h1 {
    margin-bottom: 5rem;
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    font-size: 3.0rem;
    line-height: 5rem;
}

#root .sticky-view > .sticky-1 h2 {
    padding-top: 4.0rem;
    padding-bottom: 4.0rem;
    font-size: 10.0rem;
    line-height: 10.0rem;
}

#root .sticky-view > .sticky-1 h4 {
    margin-top: 5rem;
}

#root .sticky-view > .sticky-1 .full-sticky-content .full-sticky-foreground {
    position: relative;
}

#root .sticky-view > .sticky-1 .full-sticky-content .full-sticky-foreground .content-background {
    position: absolute;
    top: 94.5%;
    left: 30rem;
    width: 35rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-1 .full-sticky-background {
    width: 100%;
    height: 100%;
    background-image: url("../../public/images/bg/bg-01.jpg");
}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-1 h1 {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        font-size: 2.4rem;
        line-height: 3.5rem;
    }

    #root .sticky-view > .sticky-1 h2 {
        padding-top: 2.1rem;
        padding-bottom: 2.1rem;
        font-size: 6.4rem;
        line-height: 7rem;
    }

    #root .sticky-view > .sticky-1 .full-directions {
        top: 8%;
        left: 46%;
    }

    #root .sticky-view > .sticky-1 .arrow {
        width: 22px;
        height: 8px;
    }

    #root .sticky-view > .sticky-1 .arrow::before, #root .sticky-view > .sticky-1 .arrow::after {
        width: 11px;
        height: 1px;
    }

    #root .sticky-view > .sticky-1 .full-sticky-content .full-sticky-foreground .content-background {
        top: 94%;
        left: 15rem;
        height: 4rem;
    }

}

@media screen and (max-width:1280px) {

    #root .sticky-view > .sticky-1 .full-directions {
        top: 9%;
        left: 45%;
    }

    #root .sticky-view > .sticky-1 .full-sticky-content .full-sticky-foreground .content-background {
        width: 25rem;
        left: 15rem;
    }

    #root .sticky-view > .sticky-1 .full-sticky-background {
        background-image: url("../../public/images/bg/bg-01-m.webp");
    }

}

@media screen and (max-width:767px), screen and (max-height:900px) {

    #root .sticky-view > .sticky-1 h1 {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 1.7rem;
        line-height: 2.8rem;
    }

    #root .sticky-view > .sticky-1 h2 {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0rem;
        font-size: 2.9rem;
        line-height: 3.5rem;
    }

    #root .sticky-view > .sticky-1 .full-directions {
        top: 8%;
        left: 46%;
    }

    #root .sticky-view > .sticky-1 .arrow {
        width: 22px;
        height: 8px;
    }

    #root .sticky-view > .sticky-1 .arrow::before, #root .sticky-view > .sticky-1 .arrow::after {
        width: 11px;
        height: 1px;
    }

    #root .sticky-view > .sticky-1 .full-sticky-content .full-sticky-foreground .content-background {
        top: 95%;
        left: 8rem;
        height: 3rem;
    }

}

/* Page 02 */

#root .sticky-view > .sticky-2 {
    background-color: variables.$color-white;
}

#root .sticky-view > .sticky-2 .full-sticky-content {
    color: variables.$color-black;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text {
    position: relative;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) {
    margin-left: 10%;
    margin-top: 5rem;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-subsection {
    transition: all 0.8s;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-subsection h4 {
    text-indent: -0.4em;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(1) .content-background:nth-child(2) {
    position: absolute;
    top: 27.5rem;
    left: -10rem;
    width: 50rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(1) .content-background:nth-child(3) {
    position: absolute;
    top: 20rem;
    left: 30rem;
    width: 20rem;
    height: 20rem;
    z-index: -1;
    background-image: url("../../public/images/background-circle-s.svg");
    background-size: cover;
    background-position: center;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(2) {
    position: absolute;
    top: 35rem;
    left: 0.5rem;
    width: 80rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3),
#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4),
#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5),
#root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
    position: absolute;
    display: none;
    z-index: -1;
}

#root .sticky-view > .sticky-2 .full-sticky-background {
    background-image: url("../../public/images/bg/bg-02.jpg");
}

@media screen and (max-width:1680px) {

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
        display: block;
        height: 4rem;
        left: 0rem;
        background-color: variables.$color-background-lightblue;
        opacity: 80%;
        transition: all 0.8s;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3) {
        width: 52.4rem;
        top: 12.9rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4) {
        width: 33.8rem;
        top: 18.5rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5) {
        width: 51.7rem;
        top: 23.9rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
        width: 20.2rem;
        top: 29rem;
    }

}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(1) .content-background:nth-child(2) {
        top: 22.5rem;
        left: -10rem;
        width: 43rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(1) .content-background:nth-child(3) {
        top: 15rem;
        left: 22rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(2) {
        top: 26.3rem;
        left: 0.5rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
        display: block;
        height: 4rem;
        left: 0rem;
        background-color: variables.$color-background-lightblue;
        opacity: 80%;
        transition: all 0.8s;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3) {
        width: 39.8rem;
        top: 9.3rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4) {
        width: 25.8rem;
        top: 13.7rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5) {
        width: 39rem;
        top: 18.1rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
        width: 15.2rem;
        top: 21.7rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-background {
        background-image: url("../../public/images/bg/bg-02-m.webp");
    }

}

@media screen and (max-width:767px), screen and (max-height:900px) {

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(1) .content-background:nth-child(2) {
        top: 13rem;
        left: -2rem;
        width: 21rem;
        height: 3rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(1) .content-background:nth-child(3) {
        top: 10rem;
        left: 12rem;
        width: 10rem;
        height: 10rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(2) {
        top: 15rem;
        left: 0rem;
        width: 30rem;
        height: 3rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5),
    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
        display: block;
        height: 2rem;
        left: 0rem;
        background-color: variables.$color-background-lightblue;
        opacity: 80%;
        transition: all 0.8s;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(3) {
        width: 23.2rem;
        top: 5.3rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(4) {
        width: 14.8rem;
        top: 7.7rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(5) {
        width: 22.8rem;
        top: 10.1rem;
    }

    #root .sticky-view > .sticky-2 .full-sticky-content .full-sticky-foreground .content-text:nth-child(2) .content-background:nth-child(6) {
        width: 8.6rem;
        top: 12.5rem;
    }

}

@media screen and (max-width:400px) and (max-height:900px) {
    
    #root .sticky-view > .sticky-2 .full-sticky-background {
        background-position-x: -42rem;
    }

}

@media screen and (max-width:380px) and (max-height:700px) {
    
    #root .sticky-view > .sticky-2 .full-sticky-background {
        background-position-x: -35rem;
    }

}

/* Page 03 */

#root .sticky-view > .sticky-3 .full-sticky-content {
    position: relative;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground {
    position: relative;
    width: 2000px;
    height: 100%;
    margin-top: 40rem;
    margin-left: auto;
    margin-right: auto;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-section {
    position: absolute;
    display: flex;
    flex-direction: column;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text {
    position: relative;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text .content-subsection {
    transition: all 0.8s;
    z-index: 1;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text:nth-child(even) {
    margin-left: 70rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text .content-background {
    position: absolute;
    height: 3rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text1 .content-background {
    top: 0rem;
    left: -5rem;
    width: 15rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text2 .content-background {
    top: 2rem;
    left: -7rem;
    width: 19rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text3 .content-background {
    top: 1rem;
    left: 2rem;
    width: 12rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text4 .content-background {
    top: 2rem;
    left: -2rem;
    width: 14rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text5 .content-background {
    top: -0.2rem;
    left: 4rem;
    width: 6rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground h6 {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap {
    display: flex;
    flex-direction: column;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap p {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap p span:nth-child(2) {
    width: 33rem;
}

#root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-background {
    background-image: url("../../public/images/bg/bg-03.jpg");
}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text:not(:first-child) {
        margin-top: 5rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text:nth-child(even) {
        margin-left: 30rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap {
        margin-top: 1rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap p span:nth-child(2) {
        margin-left: 1rem;
        text-align: left;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text .content-background {
        height: 2.5rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text1 .content-background {
        top: -0.5rem;
        left: -2rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text2 .content-background {
        top: 1rem;
        left: 7rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text4 .content-background {
        top: 0.8rem;
        left: -1rem;
        width: 17rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-background {
        background-image: url("../../public/images/bg/bg-03-m.webp");
    }

}

@media screen and (max-width:1024px) {

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground {
        margin-top: 18rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text:nth-child(even) {
        margin-left: 0rem;
    }

}

@media screen and (max-width:767px) {

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text:not(:first-child) {
        margin-top: 5rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text:nth-child(even) {
        margin-left: 0rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap {
        margin-top: 1rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap p {
        flex-direction: column;
        margin-top: 0.5rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text-wrap p span:nth-child(2) {
        margin-left: 1rem;
        text-align: left;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text .content-background {
        height: 2.5rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text1 .content-background {
        top: -0.5rem;
        left: -2rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text2 .content-background {
        top: 1rem;
        left: 7rem;
    }

    #root .sticky-view > .sticky-3 .full-sticky-content .full-sticky-foreground .content-text4 .content-background {
        top: 0.8rem;
        left: -1rem;
        width: 17rem;
    }

}

/* Page 04 */

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 60rem;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section {
    display: flex;
    flex-direction: row;
    position: relative;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
    margin-top: 5rem;
    margin-left: 15%;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-text:nth-child(2), 
#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-text:nth-child(2) {
    display: flex;
    flex-direction: column;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section .content-text {
    transition: all 0.8s;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section .content-text:nth-child(2) {
    margin-left: 5rem;
} 

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-text:nth-child(2) {
    margin-left: 8.7rem;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-text:nth-child(2) .brand-logo-row,
#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-text:nth-child(2) .brand-logo-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.2rem;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-text:nth-child(2) .brand-logo,
#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-text:nth-child(2) .brand-logo {
    width: 120px;
    height: 40px;
    margin-right: 2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
    position: absolute;
    top: 6.5rem;
    left: -10rem;
    width: 54rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background {
    position: absolute;
    top: 3.5rem;
    left: 22rem;
    width: 47rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-background {
    background-image: url("../../public/images/bg/bg-04.jpg");
}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section .content-text h4 {
        width: 33rem;
        padding-left: 0rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
        top: 5.5rem;
        left: -4rem;
        width: 51rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background {
        top: 3.5rem;
        left: 17rem;
        width: 36rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-background {
        background-image: url("../../public/images/bg/bg-04-m.webp");
    }

}

@media screen and (max-width:1024px) {

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section {
        flex-direction: column;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section .content-text:nth-child(2) {
        margin-top: 5rem;
        margin-left: 0rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2),
    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-text:nth-child(2) {
        margin-left: 0rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-text:nth-child(2) .brand-logo,
    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-text:nth-child(2) .brand-logo {
        width: 90px;
        height: 30px;
    }

}

@media screen and (max-width:767px) {

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground {
        margin-top: 40rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
        top: 3.3rem;
        left: -4rem;
        width: 23.5rem;
        height: 3rem;
    }

    #root .sticky-view > .sticky-4 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background {
        top: 3.2rem;
        left: 9.5rem;
        width: 36rem;
        height: 3rem;
    }

}

/* Page 05 */

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground {
    position: relative;
    height: 100%;
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground .content-section {
    margin-top: 30rem;
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text {
    position: absolute;
    top: 45.3rem;
    right: 21rem;
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text .content-section {
    display: flex;
    flex-direction: column;
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text .content-subsection {
    display: flex;
    flex-direction: row;
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text h6 {
    line-height: 3.8rem;
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background {
    background-image: url("../../public/images/bg/bg-05.jpg");
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-map {
    position: absolute;
    z-index: 0;
    top: -5rem;
    right: 46rem;
    width: 80rem;
    height: 100rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../public/images/south-korea-circle-dots.svg");
}

#root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-guide {
    position: absolute;
    z-index: 0;
    top: -5rem;
    right: 46rem;
    width: 80rem;
    height: 100rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../public/images/south-korea-guide.svg");
}

@media screen and (max-width:1680px) {

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text {
        top: 36rem;
        right: 25.5rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text h6 {
        font-size: 1.5rem;
        line-height: 2.7rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text p {
        font-size: 1.2rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-map {
        top: 10rem;
        right: 46rem;
        width: 56rem;
        height: 70rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-guide {
        right: 46rem;
        top: 10rem;
        width: 56rem;
        height: 70rem;
    }

}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground .content-section {
        margin-top: 31.5rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text {
        top: 34.7rem;
        right: 25.5rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text h6 {
        font-size: 1.5rem;
        line-height: 2.7rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text p {
        font-size: 1.2rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-map {
        top: 10rem;
        width: 56rem;
        height: 70rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-guide {
        top: 10rem;
        width: 56rem;
        height: 70rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background {
        background-image: url("../../public/images/bg/bg-05-m.webp");
    }

}

@media screen and (max-width:1280px) {

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text {
        top: 18.7rem;
        right: 12rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text h6 {
        font-size: 1.2rem;
        line-height: 1.9rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text p {
        font-size: 1.2rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-map {
        top: 10rem;
        right: 30rem;
        width: 40rem;
        height: 50rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-guide {
        top: 10rem;
        right: 30rem;
        width: 40rem;
        height: 50rem;
    }

}

@media screen and (max-width:1024px) {

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground .content-section {
        margin-top: 20rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text {
        top: 35.7rem;
        right: 9.3rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text h6 {
        font-size: 1.2rem;
        line-height: 2.4rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text p {
        font-size: 1.0rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-map {
        top: 40rem;
        right: 30rem;
        width: 24rem;
        height: 30rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-guide {
        top: 40rem;
        right: 30rem;
        width: 24rem;
        height: 30rem;
        background-image: url("../../public/images/south-korea-guide-s.svg");
    }

}

@media screen and (max-width:767px) {

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground .content-section {
        margin-top: 12.5rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text {
        top: 31rem;
        left: 14rem;
        width: 21rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text h6 {
        font-size: 1.0rem;
        line-height: 1.6rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-foreground-text p {
        font-size: 0.8rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-map {
        top: 33rem;
        left: 0rem;
        width: 16rem;
        height: 20rem;
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background-element-guide {
        top: 33rem;
        left: 0rem;
        width: 16rem;
        height: 20rem;
        background-image: url("../../public/images/south-korea-guide-s.svg");
    }

    #root .sticky-view > .sticky-5 .full-sticky-content .full-sticky-background {
        background-position: left;
    }

}

/* Page 06 */

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 18%;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
    position: absolute;
    top: 24.5rem;
    left: 6rem;
    width: 47rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-left: 30%;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text2 {
    margin-top: 5rem;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-subsection {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text3 {
    margin-left: 0;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text4 {
    margin-left: 5rem;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text5 {
    margin-left: 5rem;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background {
    position: absolute;
    top: 12.5rem;
    left: 6rem;
    width: 67rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-background {
    background-image: url("../../public/images/bg/bg-06.jpg");
}

@media screen and (max-width:1680px), screen and (max-height:2160px) {

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground {
        margin-top: 10%;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
        margin-left: 16%;
    }

}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground {
        margin-top: 18rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
        margin-top: 2rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
        top: 18.5rem;
        left: 5rem;
        width: 37rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background {
        top: 8.5rem;
        left: 6rem;
        width: 63rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-background {
        background-image: url("../../public/images/bg/bg-06-m.webp");
    }

}

@media screen and (max-width:1280px) {

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
        margin-left: 10%;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text3,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text4,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text5 {
        width: 24rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text4,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text5 {
        margin-left: 2rem;
    }

}

@media screen and (max-width:1024px) {

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
        margin-left: 5%;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text3,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text4,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text5 {
        width: 20rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text4,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text5 {
        margin-left: 2rem;
    }

}

@media screen and (max-width:767px) {

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) h5 {
        width: 35rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) {
        margin-left: 0rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-subsection {
        flex-direction: column;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text4,
    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-text5 {
        margin-top: 2rem;
        margin-left: 0rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
        top: 17.5rem;
        left: 5rem;
        width: 14rem;
        height: 3rem;
    }

    #root .sticky-view > .sticky-6 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background {
        top: 8.5rem;
        left: 6rem;
        width: 28rem;
        height: 3rem;
    }

}

/* Page 07 */

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section {
    display: flex;
    flex-direction: row;
    margin-top: 10rem;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
    position: absolute;
    top: 13.5rem;
    left: 21.5rem;
    width: 27rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section .content-text3 {
    margin-left: 50rem;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-text3 .brand-logo-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 1.0rem;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-text3 .brand-logo {
    width: 200px;
    height: 60px;
    margin-right: 4rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background:nth-child(3) {
    position: absolute;
    top: 6.5rem;
    left: -2rem;
    width: 87rem;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background:nth-child(4) {
    position: absolute;
    top: -2rem;
    left: 24rem;
    width: 20rem;
    height: 20rem;
    z-index: -1;
    background-image: url("../../public/images/background-circle-s.svg");
    background-size: cover;
    background-position: center;
}

#root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-background {
    background-image: url("../../public/images/bg/bg-07.jpg");
}

@media screen and (max-width:1680px) {

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section .content-text3 {
        margin-left: 20rem;
    }

}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground {
        margin-top: 10rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
        left: 16.5rem;
        width: 22rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background:nth-child(3) {
        top: 5.5rem;
        width: 77rem;
        height: 4rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background:nth-child(4) {
        left: 18rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-background {
        background-image: url("../../public/images/bg/bg-07-m.webp");
    }

}

@media screen and (max-width:1280px) {

    #root .sticky-view > .sticky-7 .full-sticky-content {
        justify-content: start;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground {
        margin-top: 12rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section {
        flex-direction: column;
        margin-top: 5rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section .content-text3 {
        margin-left: 0rem;
        margin-top: 5rem;
    }

}

@media screen and (max-width:767px), screen and (max-height:900px) {

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section {
        flex-direction: column;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section .content-text3 {
        margin-left: 0rem;
        margin-top: 15rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(1) .content-background {
        top: 8.0rem;
        left: 12.5rem;
        width: 14rem;
        height: 3rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background:nth-child(3) {
        top: 5.5rem;
        width: 27rem;
        height: 3rem;
    }

    #root .sticky-view > .sticky-7 .full-sticky-content .full-sticky-foreground .content-section:nth-child(2) .content-background:nth-child(4) {
        top: -2rem;
        left: 10rem;
        width: 12rem;
        height: 12rem;
    }

}

/* Page 08 */

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-foreground {
    margin-left: 50%;
    z-index: 1;
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-foreground .content-text1 a:link,
#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-foreground .content-text1 a:hover,
#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-foreground .content-text1 a:visited {
    color: variables.$color-white;
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-foreground .content-text2 {
    margin-top: 5rem;
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background {
    background-image: url("../../public/images/bg/bg-08.jpg");
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element {
    overflow-y: hidden;
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background1 {
    position: absolute;
    top: 50%;
    left: 53%;
    width: 47%;
    height: 5rem;
    background-color: variables.$color-background-red;
    opacity: 80%;
    z-index: -1;
    transition: all 0.8s;
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background2 {
    position: absolute;
    bottom: 54%;
    right: 42%;
    width: 20rem;
    height: 20rem;
    z-index: -1;
    background-image: url("../../public/images/background-circle-s.svg");
    background-size: cover;
    background-position: center;
}

#root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background3 {
    position: absolute;
    bottom: 0%;
    right: 24%;
    width: 100rem;
    height: 60rem;
    z-index: -1;
    background-image: url("../../public/images/background-circle-l.svg");
    background-size: cover;
    background-position: top;
    overflow-y: hidden;
}

@media screen and (max-width:1680px) {

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background3 {
        right: 12%;
    }

}

@media screen and (max-width:1440px), screen and (max-height:1024px) {

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background1 {
        top: 50.5%;
        height: 4rem;
    }

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background3 {
        right: -12%;
    }

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background {
        background-image: url("../../public/images/bg/bg-08-m.webp");
    }

}

@media screen and (max-width:767px), screen and (max-height:900px) {

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-foreground {
        margin-left: 0rem;
    }

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background1 {
        height: 3rem;
        left: 3%;
        top: 52%;
    }

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background2 {
        bottom: 54%;
        right: 36%;
        width: 12rem;
        height: 12rem;
    }

    #root .sticky-view > .sticky-8 .full-sticky-content .full-sticky-background-element .content-background3 {
        bottom: 0%;
        right: -50%;
        width: 60rem;
        height: 36rem;
    }

}