@use './global/variables';

html {
    font-family: variables.$base-font-family;
    font-size: variables.$base-font-size;
    font-weight: 400;
    font-style: normal;
    color: variables.$color-dark;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

address {
    font-style: inherit;
}
  
#root {
    font-size: 1.4rem;
    line-height: 2em;
}

#root h1, 
#root h2, 
#root h3, 
#root h4, 
#root h5, 
#root h6, 
#root p {
    margin: 0;
}

#root h1, 
#root h2, 
#root h3, 
#root h4, 
#root h5, 
#root h6, 
#root p,
#root li {
    padding: 0 1rem;
}

#root button, 
#root input {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: #fff;
    background: variables.$color-accent-dark;
    border-radius: 0.5rem;
}

#root button:hover,
#root input[type=button]:hover {
    background: variables.$color-accent-light;
}

#root button, #root input[type=button] {
    border: 0px;
    cursor: pointer;
}

#root input[type=checkbox] {
    height: 1.6rem;
    width: 1.6rem;
}

#root h1 {
    padding-top: 4.0rem;
    padding-bottom: 4.0rem;
    font-size: 10.0rem;
    line-height: 10.0rem;
}

#root h2 {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
    font-size: 8.0rem;
    line-height: 9.5rem;
}

#root h3 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    font-size: 6.0rem;
    line-height: 7.5rem;
}

#root h4 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    font-size: 4.0rem;
    line-height: 5.5rem;
}

#root h5 {
    padding-top: 1.0rem;
    padding-bottom: 1.0rem;
    font-size: 3.0rem;
    line-height: 5rem;
}

#root h6 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 2.0rem;
}

#root p {
    font-size: 1.4rem;
    font-family: variables.$small-font-family;
}

@media screen and (max-width:1440px), screen and (max-height:1024px) {
    #root h1 {
        padding-top: 2.1rem;
        padding-bottom: 2.1rem;
        font-size: 6.4rem;
        line-height: 7rem;
    }

    #root h2 {
        padding-top: 1.4rem;
        padding-bottom: 1.4rem;
        font-size: 4.2rem;
        line-height: 5.0rem;
    }

    #root h3 {
        padding-top: 1.0rem;
        padding-bottom: 1.0rem;
        font-size: 3.6rem;
        line-height: 4.4rem;
    }

    #root h4 {
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
        font-size: 3.0rem;
        line-height: 4.2rem;
    }

    #root h5 {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        font-size: 2.4rem;
        line-height: 3.5rem;
    }

    #root h6 {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    #root p {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        font-size: 1.4rem;
        line-height: 2.2rem;
    }
}

@media screen and (max-width:767px), screen and (max-height:900px) {
    #root h1 {
        padding-top: 0.4rem;
        padding-bottom: 0.4rem;
        padding-left: 0rem;
        font-size: 2.9rem;
        line-height: 3.5rem;
    }

    #root h2 {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 2.6rem;
        line-height: 3.1rem;
    }

    #root h3 {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 2.3rem;
        line-height: 2.8rem;
    }

    #root h4 {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 1.8rem;
        line-height: 2.4rem;
    }

    #root h5 {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 1.7rem;
        line-height: 2.8rem;
    }

    #root h6 {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 1.4rem;
        line-height: 1.8rem;
    }

    #root p {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        padding-left: 0rem;
        font-size: 1.2rem;
        line-height: 2rem;
    }
}
 
#root a {
    text-decoration: none;
    color: variables.$color-blue;
}

#root a:hover {
    color: transparentize(variables.$color-blue, 0.25);
}

#root label {
    display: flex;
    align-items: center;
}