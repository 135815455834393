@use './global/variables.scss';

body {
    background-color: #22221F;
}

#root {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.header-wrapper {
    display: block;
    align-items: center;
    position: absolute;
    top: 0;
    flex-grow: 0;
    flex-wrap: wrap;
    z-index: 100;
    width: 100%;
}

.header-wrapper > .header {
    display: block;
    max-width: 2000px;
    margin-top: 5rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5rem;
}

.header-wrapper > .header > img.logo {
    width: 10rem;
    height: 10rem;
}

#root .scrolling-view {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
}

@media screen and (max-width:767px) {

    .header-wrapper > .header {
        margin-left: 2rem;
        margin-top: 2rem;
        padding-left: 0rem;
    }

    .header-wrapper > .header > img.logo {
        width: 6rem;
        height: 6rem;
    }

}